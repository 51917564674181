<template>
  <div id="landing0323">
    <section
      class="img-container thanks"
      style="background-image: url('/imgs/landing_0323/hero.png')"
    >
      <div class="container">
        <h1>{{ $lang("title") }}</h1>
        <h2>{{ $lang("subtitle") }}</h2>
        <h5>{{ $lang("text") }}</h5>

        <a href="/">
          <img src="/svgs/logo_5.svg" alt="Logo NLC" class="logo_2 logo-nlc" />
        </a>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
